import styled from "styled-components"

export const MemoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const MemoryContainer = styled.div`
  color: black;
  box-shadow: none;
  margin: 10px 10px 10px 0;
  width: 350px;
  height: 160px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  h3 {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    margin: 0 0 5px 0;
  }
  p {
    font-size: 14px;
    margin: 0;
  }

  &:hover {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  }
`
